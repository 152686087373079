import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import ValidationError from '@fuww/library/src/ValidationError';
import loginWithTokenMutation from '../../lib/mutations/loginWithToken.graphql';
import SocialLoginButton from './SocialLoginButton';

const LoginWithProviderForm = ({
  provider,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const client = useApolloClient();

  const [
    createSession,
  ] = useMutation(loginWithTokenMutation, {
    onCompleted: (data) => {
      setDisabled(false);
      localStorage.setItem('accessToken', data.loginWithToken.accessToken);
      window.plausible('Login', {
        props: {
          provider,
        },
      });
      client.resetStore();
    },
    onError: (error) => {
      setDisabled(false);
      setErrorMessage(error.message);
    },
  });

  return (
    <>
      <SocialLoginButton
        labelKey="login.logInWithProvider"
        provider={provider}
        onLogin={useCallback(() => setDisabled(true), [setDisabled])}
        onLoginSuccess={useCallback(({ accessToken, providerArgument }) => {
          setDisabled(true);
          createSession({
            variables: {
              accessToken,
              provider: providerArgument,
            },
          });
        }, [setDisabled, createSession])}
        setErrorMessage={setErrorMessage}
        setDisabled={setDisabled}
        disabled={disabled}
      />
      { errorMessage
      && (
      <ValidationError>
        { errorMessage }
      </ValidationError>
      )}
    </>
  );
};

LoginWithProviderForm.propTypes = {
  provider: PropTypes.string.isRequired,
};

export default LoginWithProviderForm;
