import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import ArticleAccess from '@fuww/library/src/Login/ArticleAccess';
import Divider from '@fuww/library/src/Login/Divider';
import Button from '@fuww/library/src/Button';
import { useIntl } from 'react-intl';
import LoginForm from './LoginForm';
import LoginWithProviderForm from './LoginWithProviderForm';
import { Link } from '../../routes.mjs';
import { saveRouterState } from '../../lib/routerState';
import messages from '../../lib/messages.mjs';

const ArticleAccessForm = () => {
  const intl = useIntl();
  const { query, route } = useRouter();

  useEffect(() => {
    saveRouterState({ route, query });
  }, [route, query]);

  return (
    <ArticleAccess
      invitation={intl.formatMessage(messages.articleAccessInvitation)}
    >
      <LoginForm />
      <Divider dividerText="OR" />
      <LoginWithProviderForm
        provider="google"
      />
      <LoginWithProviderForm
        provider="linkedin"
      />
      <Link route="register" passHref>
        <Button fullWidth variant="text" className="create-account">
          {intl.formatMessage(messages['register.createAccount'])}
        </Button>
      </Link>
    </ArticleAccess>
  );
};

ArticleAccessForm.propTypes = {};

ArticleAccessForm.defaultProps = {};

export default ArticleAccessForm;
